@import './style/_variables.scss';
@import './style/Fonts.scss';
@import './theme.scss';
@import './routes/user-management/UserManagement.module.scss';
@import './routes/core/documents/components/createFolder/CreateFolder.module.scss';
@import './routes/core/documents/components/createFolderWithPermission/CreateFolder.module.scss';
@import 'src/routes/core/documents/components/uploadFile/uploadFile.module.scss';
@import 'src/routes/core/documents/components/updateFileVersion/UpdateFileVersion.module.scss';
@import 'routes/core/users/Users.module.scss';
@import 'routes/core/users/addUser/AddUserTaxand.module.scss';
@import './components/appComponents/Layout.module.scss';
@import './routes/core/documents/sass/documents.module.scss';
body {
  user-select: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 9px;
  background: transparent;
  transition: width 0.2s ease;
  margin-right: 3px;
  
}

/* Track */
* {
  scrollbar-width: thin;
  scrollbar-color: #1f2553 transparent;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  margin-right: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f2553;
  border-radius: 5px;
  margin-right: 3px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f255371;
}

.error{
  color: red;
  margin-top: 10px;
}

.success{
  color: green;
  margin-top: 10px;
}

.input{
  width: auto;
}

.material-icons{
  color: #1f2553
}
.modal-firm {
  position: absolute;
  width: 650px;
  top: 50%;
  left: 50%;
  overflow:hidden;
  height:auto;
  display:grid;
  transform: translate(-50%, -50%);

  .paper{
    padding: 20px;
  }

}
.modal-users {
  position: absolute;
  width: 650px;
  top: 50%;
  left: 50%;
  overflow:hidden;
  height:auto;
  display:grid;
  transform: translate(-50%, -50%);

  .paper{
    padding: 20px;
  }

}


.modal {
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  overflow:scroll;
  height:90%;
  display:grid;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  margin-left: 40px;
  margin-right: 40px;

  .paper{
      padding: 20px;
  }

}
.root{
  width: 100%;
}

.paper{
  width: 100%;
  margin-bottom: 20px;
}

