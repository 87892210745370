// This file is imported in Home.module.scss

:root {
    --bg:  #ffffff;
    --bg-accent: #d6d6d6;
    --text-color: $primary-color;
    --nav-size: 60px;
    --border: none;
    --border-radius: 10px;
    --speed: 500ms; 
  }
  
  .MenuIcon
      {
          fill: $primary-color;
          width: 30px;
          height: 30px;
          margin-right: 1.25rem;
          cursor: pointer;
          transition: all 0.2s ease;
          z-index: 2;
      }
      .MenuIcon:hover
      {
          fill: $hover-color ;
      }
      .Title
      {
          font-family: 'MontserratBold';
          color: $primary-color;
          font-size: 1.25rem;
      }
      .fadeout {
          
          transform: translateY(-250%);
          -moz-transition: transform 0.4s ease-in-out;
         -o-transition: transform 0.4s ease-in-out;
          -webkit-transition: transform 0.4s ease-in-out;
          transition: transform 0.4s ease-in-out;
          transition: transform 0.4s ease-in-out;
      }
      .fadein {
         // opacity: 1;
          transform: translateY(0%);
          transition: transform 0.4s ease-in-out;
          //transition: opacity 0.4s ease-in-out;
      }
      .Dropdown
      {
          
          top: 60px;
          right: 50px;
          z-index: 100;
          position: absolute;
          width: 200px;
          background-color: var(--bg);
          border: var(--border);
          border-radius: var(--border-radius);
          padding: 1rem;
          overflow: hidden;
          box-shadow: 0px 10px 50px rgba(0,0,0,0.1);
          .menuItem
          {
              font-family: 'ArialBD';
              height: 30px;
              display: flex;
              align-items: center;
              font-size: 14px;
              border-radius: var(--border-radius);
              transition: background var(--speed);
              padding: 0.5rem;
              cursor: pointer;
          }
          .menuItem:hover
          {
              background-color: rgba(13, 12, 34, 0.068);
          }
      }
      .icon-button {
          --button-size: calc(var(--nav-size) * 0.5);
          width: var(--button-size);
          height: var(--button-size);
          background-color: #c7c7c724;
          border-radius: 50%;
          padding: 5px;
          margin-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: filter 300ms;
      }
        
      .icon-button:hover {
        opacity: 0.9;
      }
      
      .icon-button svg { 
        fill: var(--text-color) !important;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0 !important;
      }