.card {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 24px 0 rgba(20, 38, 72, 0.05);
  background-color: #fff;
  padding: 24px;
  min-height: fit-content;
  .actions{
    margin-left: auto;
    align-self: center;
  }
}
.cardGraphOffset {
  padding-bottom: 0px;
}