.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  display: flex;
  opacity: 1;
  align-items: center;
}

.popup_Bg{
  opacity: 0;
  display: flex;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  backdrop-filter: blur(5px);
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 99;
  visibility: hidden;
  transform: scale(0);
}

.popup_Bg.rbt-active{
  opacity: 1;
  visibility: inherit;
  transform: scale(1);
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.carde {
  width: 800px;
  height: 500px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px 30px;
  gap: 13px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
  margin: auto;
  background-color: #0D79F77D;

  .container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: 100%;
    padding: 0px;
  }

  .element-885c050 {
    width: 14.88%;
    position: relative;
    min-height: 1px;
    display: flex;
  }

  .element-440a43a {
    width: 62.292%;
  }

  .element-55d00d1 {
    width: 22.824%;
  }

  .heading-title {
    color: #fff;
    font-family: Sans-serif;
    font-size: 52px;
    font-weight: 700;
    letter-spacing: 1.44px;
    margin: 0;
    padding: 10px 0px;
  }

  .divider-separator{
    border-top: 1px solid white;
    width: 46%;
    display: block;
  }

  .icon-list-items {
    list-style-type: none;
    margin-left: 10px;
    padding: 0;

    li {
      display: flex;
      align-items: center;
    }
  }
}
.background-overlay{
  background-image: url("../../../../assets/banner_image_popup.webp");
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: multiply;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.carde .element-6af0eb9 .element-populated {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #fff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 10px;
  width: 100%;
}
.carde .date-item span {
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}
.carde .element-440a43a .element-populated{
  padding: 10px;
}
.carde .element-440a43a .heading-sub-title{
  font-family: Sans-serif;
  color: #fff;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: -0.9px;
  margin: 0;
}
.carde .element-440a43a .heading-title {
  color: #fff;
  font-family: Sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: -2.1px;
}
.carde .element-64dd4e5 .icon-list-item > .icon-list-text {
  font-size: 18px;
  font-weight: 700;
}

.carde .element-440a43a .icon-list-item > .icon-list-text {
  font-size: 18px;
  font-weight: 700;
  color: white;
  font-family: Sans-serif;
  margin-left: 5px;
}

/* col 3 */
.carde .element-55d00d1 > .element-populated {
  border-style: solid;
  border-width: 0px 10px 0px 0px;
  border-color: #142C46;
  margin: 0px 0px 0px 40px;
  padding: 10px;
}
.carde .element-55d00d1 .block-count-down {
  margin: 0;
  padding: 0 15px;
}
.carde .element-55d00d1 .heading-title {
  color: #ffffff;
  font-family: Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.block-count-down .countdown .counter-item {
  width: 100%;
  position: relative;
  border-radius: 0;
  padding: 10px 0;
}
.counter-item .countdown-number {
  font-family: Sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}
.block-count-down .countdown .counter-item .smalltext {
  position: static;
  text-transform: initial;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 0.8;
  color: #fff;
  font-weight: 600;
  font-family: Sans-serif;
  margin-left: 10px;
}
.block-count-down .countdown .counter-item:not(:last-child) {
  border-bottom: 2px dotted #4f5b68;
}
.rbt-hide-popup{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carde .element-populated button{
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  background-color: transparent;
  padding: 4px 20px 4px 20px;
  border: 1px solid #fff;
  margin-right: 15px;
  margin-top: 21px;
  display: inline-flex;
  align-items: center;
  width: auto;
}
.carde .element-populated button:hover{
  color: #222850;
  background-color: #ffffff9e;
}

.carde .element-populated .button-icon svg{
  fill: none !important;
  margin-right: 13px !important;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.carde .element-populated button:hover .button-icon svg{
  fill: #222850 !important;
}

/* Add responsive design as needed */
@media (max-width: 768px) {
  .carde .container {
    display: block;
  }
  .swiper-slide {
    display: block;
    overflow: scroll;
  }
  .carde .element-55d00d1 {
    width: 100%;
    display: block;
  }
  .carde .element-55d00d1 > .element-populated {
    display: flex;
    margin: 0px;
  }
  .countdown.etn-countdown-wrap.clearfix {
    display: flex;
  }
  .block-count-down .countdown .counter-item:not(:last-child) {
    border-bottom: none;
    margin-right: 18px;
  }
  .carde .element-55d00d1 .heading-title {
    font-size: 13px;
    display: flex;
    align-items: center;
  }
  .carde .element-55d00d1 > .element-populated {
    border: none;
  }
  .carde .element-440a43a, .carde .element-885c050 {
    width: 100%;
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .carde .element-885c050 {
    display: block;
  }
}