@import 'style/_variables.scss';

@media only screen and (max-width: 935px){
  .InputHalfSize{
    width: 100% !important;
  }

  .InputSameRow{
    display: flex;
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 935px){
  .usernameAv
  {
    flex-direction : column !important;
  }
  .ResetPassword
  {
    margin-top : 10px !important;
  }
}
.usernameAv
{
  display : flex ;
  justify-content : space-between ;
  align-items : baseline ;

}
.flexHeader
{
  display : flex ;
  justify-content: center;
}
.UserModal
{    -webkit-user-select: text;
  -webkit-touch-callout: default;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  .UserinfoContent
  {
    display: flex;
    justify-content: space-between;
  }
  .LeftColumn
  {
    width: 48%;
  }
  .RightColumn
  {
    width: 48%;
  }
  .ResetButton{

    margin-top: 22px;
    margin-bottom: 1.25rem;
    overflow: auto;
    background-color: $primary-color;
    color: #fafafa;
    padding: 20px 45px;
    cursor: pointer;
    border: none;
    font-size: 0.938rem;
    font-family: 'ArialBD' ;
    border-radius: 10px;
    transition: all 0.2s ease;
    outline: none;
  }
  .ResetButton:hover
  {
    box-shadow: 0px 0px 10px  #22285038;
    -webkit-transform: translate(0px, -3px);
    -ms-transform: translate(0px, -3px);
    transform: translate(0px, -3px);
    transition: all 0.2s ease;
  }
  .flexHeader{
    display : flex ;
    align-items: center;
    justify-content: flex-start;
  }
  .ProfileHeader
  {
    position : relative ;
    
    margin-bottom: 20px;
    .UploadContainer
    {
      position : absolute ;
      width : 25px;
      height : 25px;
      border-radius: 50%;
      background-color : #fafafa;
      color : #1f2553;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      margin-top: -25px;
      margin-left: 55px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
    .UploadContainerLeft {
      border-color: white;
      position : absolute ;
      width : 25px;
      height : 25px;
      border-radius: 50%;
      background-color : #fafafa;
      color : #1f2553;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      margin-top: -25px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
    .UploadContainerAdd {
      border-color: white;
      position : absolute ;
      width : 25px;
      height : 25px;
      border-radius: 50%;
      background-color : #fafafa;
      color : #1f2553;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      margin-top: -55px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
    .UploadContainer:hover {
      background-color : #1f2553;
      color : #fafafa;
    }
    .UploadContainerLeft:hover {
      background-color : #1f2553;
      color : #fafafa;
    }
    .UploadFileContainer {
      display : flex ; 
      flex-direction : column;
      margin-left: 50px;

      .UploadFile {
        border-radius: 10px;
        border: none;
        display : flex ; 
        justify-content : center ;
        align-items : center;
        padding: 8px 10px; 
        font-size: 13px;
        font-family: 'Arial' ;
        color: #1f2553;
        background-color: #fafafa;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        border : 1px solid #1f25532f;
        margin-bottom: 5px;

        span {
          margin-left: 5px;
          margin-right: 5px;
        }

      }
    }
    .UploadFile:hover {
      border : 1px solid transparent;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.166);
      -webkit-transform: translate(0px, -3px);
      -ms-transform: translate(0px, -3px);
      transform: translate(0px, -3px);
    }
    
    input[type="file"] {
      display: none;
    }

  }
  .ContactTitle {
    font-family: 'ArialBD';
    color: $primary-color;
    margin-top : 10px;
  }
  .ErrorLabel {
    color : red ;
  }
  .WarningLabel {
    color : black ;
  }
  .ContactContainer {
    padding : 20px ;
    border-radius : 20px ;  
    background-color: rgba(240, 240, 240, 0.664);

    .InputFullSize {
      width : 100%;
    }

    .InputHalfSize {
      width: 48%;
    }

    .InputLabel {
      font-family: 'ArialBD';
      font-size: 14px;
      color : #2a2a2a ;
    }

    .InputSameRow {
      display: flex;
      justify-content: space-between;
      margin-top : 15px;
      align-items: center;
    }
  }
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline {
  width: 100%;
}
