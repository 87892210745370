div.rbt-toolbar {
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  display: contents;
}
section.rbt-sidearea {
  width: 282px;
  height: 100%;
  min-height: 100%;
  position: fixed;
  right: -282px;
  top: 0;
  z-index: 6669991;
  -webkit-transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
}
.rbt-sidearea.rbt-active {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  box-shadow: 0 0 9.8px 0.2px rgba(0, 0, 0, 0.14);
}
.rbt-loaded .rbt-dropdown {
  -webkit-transform: translateX(63%);
  transform: translateX(63%);
  top: 90px;
}
.rbt-dropdown:hover {
  transform: translateX(0%);
}

.rbt-loaded .rbt-dropdown_event {
  -webkit-transform: translateX(63%);
  transform: translateX(63%);
  top: 140px;
}
.rbt-dropdown_event:hover {
  transform: translateX(0%);
}

.rbt-dropdown, .rbt-dropdown_event {
  background-color: #142C46;
}
.rbt-dropdown, .rbt-dropdown_event {
  position: absolute;
  left: -115px;
  z-index: 6669992;
  font-size: 10px !important;
  letter-spacing: .11em;
  display: block;
  height: 42px;
  width: 115px;
  line-height: 42px;
  font-family: sans-serif;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all .25s cubic-bezier(0.23, 1, 0.32, 1) .45s;
  transition: all .25s cubic-bezier(0.23, 1, 0.32, 1) .45s;
  border-radius: 7px 0px 0px 7px;
}

.rbt-dropdown .rbt-btn, .rbt-dropdown_event .rbt-btn {
  position: relative;
  display: block;
  cursor: pointer;
  font-size: 0 !important;
  line-height: 42px !important;
  font-weight: 500;
}
span.rbt-icon {
  color: #fff;
  width: 42px;
  display: inline-block;
  line-height: 15px !important;
  text-align: center;
  vertical-align: middle;
  position: relative;
}
.rbt-dropdown span.rbt-icon svg:nth-child(2) {
  height: 29px !important;
}
.rbt-dropdown span.rbt-icon svg:nth-child(1) {
  margin-top: -7px;
}
.rbt-dropdown .rbt-icon svg {
  transition: opacity .1s ease;
}
div.rbt-toolbar svg {
  position: initial;
  margin-right: 0px !important;
}
.rbt-dropdown .rbt-icon svg:nth-child(2) {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
span.rbt-text-name {
  color: #fff;
  font-size: 10px !important;
  letter-spacing: .11em !important;
}
.rbt-list-holder {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 6669993;
  background-color: #fff;
}
.rbt-list {
  height: calc(100% - 40px - 11px);
  font-weight: 400;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #fff;
  margin-right: -18px;
}
.rbt-list-inner {
  max-width: 280px;
}
.rbt-logo {
  width: 100%;
  display: block;
  padding: 7px 0;
  box-sizing: border-box;
}
div.rbt-toolbar button {
  display: block;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
  transition: color .2s ease-out;
  cursor: pointer;
  padding: 15px 24px 0px 24px;
  background: white;
  border: none;
  width: 100%;
}
div.rbt-toolbar .rbt-logo button {
  padding: 9px 64px;
}
div.rbt-toolbar button.active {
  background-color: whitesmoke;
}
.rbt-logo .logo-svg-holder {
  display: inline-block;
  vertical-align: middle;
}
.rbt-logo .logo-text-holder {
  vertical-align: middle;
  display: inline-block;
  color: #222850;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 0 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0;
}
.rbt-list-new, .rbt-list-related {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  display: inline-block;
  margin: 16px 0 10px;
  text-transform: uppercase;
  letter-spacing: .13em;
}
.rbt {
  text-align: left;
  font-size: 0;
  margin: 0 0 5px;
  position: relative;
  padding-left: 33px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.rbt svg {
  width: 25px;
  height: 25px;
  position: absolute !important;
  left: -9px;
  top: 0;
}
.rbt-title {
  font-size: 14px !important;
  line-height: 20px !important;
  color: #626161;
  display: block;
  margin: 0;
  transition: color .2s ease-out;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.rbt-description {
  vertical-align: middle;
  color: #919191;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.rbt-category {
  vertical-align: middle;
  color: #c4c4c4;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 18px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: italic;
}
.rbt-category span.edit {
  font-size: 8px;
  float: right;
  color: #8e8e8e;
  display: none;
  align-content: center;
  align-items: center;
  line-height: 10px;
  margin-top: 5px;
  letter-spacing: 0em;
}
.rbt-category span.edit.active {
  display: grid;
}
.rbt-category span.edit svg {
  fill: #8e8e8e;
  width: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: inherit !important;
  display: block;
  height: 20px;
  margin: auto !important;
}
.rbt-sep{
  width: 100%;
  margin: 0 auto 0 auto;
  height: 1px;
  background: #e2e2e2;
}
.rbt-date {
  vertical-align: middle;
  color: #848484;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .02em;
  text-transform: uppercase;
  line-height: 20px;
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
.rbt-list-bottom {
  height: 40px;
  background-color: #142C46;
  width: 100%;
  display: block;
  position: relative;
  bottom: 0;
  margin-top: 11px;
  box-shadow: 0 0 7.28px 0.72px rgba(199, 199, 199, 0.2);
}
.rbt-list-bottom .rbt-link-holder {
  font-size: 0;
  line-height: 40px;
  text-align: center;
}
.rbt-link-holder .link-text-holder {
  display: inline-block;
  color: #fff;
  font-size: 9px;
  font-weight: 500;
  margin: 0;
  letter-spacing: .13em;
  line-height: 35px;
  vertical-align: bottom;
}
.rbt-link-holder .link-svg-holder {
  display: inline-block;
  margin-left: 7px;
  line-height: 30px;
  vertical-align: bottom;
}
.rbt-active .rbt-dropdown .rbt-icon svg:nth-child(1) {
  opacity: 0;
  transition-delay: .2s;
}
.rbt-active .rbt-dropdown .rbt-icon svg:nth-child(2) {
  opacity: 1;
  transition-delay: .3s;
  margin-top: -3px;
}
.rbt-active .rbt-purchase-text, .rbt-active .rbt-text-name {
  opacity: 0;
  -webkit-transition: opacity .1s;
  transition: opacity .1s;
}
.rbt-active .rbt-dropdown {
  -webkit-transition: all .2s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all .2s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translateX(73px);
  transform: translateX(73px);
}
.rbt-active .rbt-dropdown_event:hover {
  transform: translateX(63%);
}

.rbt-btn > span:nth-child(1) > svg.active:nth-child(1) > path:nth-child(8){
  fill:red;
}
