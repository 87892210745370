.container_upload {
  height: 300px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 5px;
  background-color: rgba(0, 110, 255, 0.041);
}

.header_upload {
  flex: 1;
  width: 100%;
  border: 2px dashed #222850;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header_upload svg {
  height: 100px;
}

.header_upload p {
  text-align: center;
  color: black;
}

.footer_upload {
  background-color: rgba(0, 110, 255, 0.075);
  width: 100%;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: black;
  border: none;
}

.footer_upload svg {
  height: 130%;
  fill: #222850;
  background-color: rgba(70, 66, 66, 0.103);
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.205);
}

.footer_upload p {
  flex: 1;
  text-align: center;
  margin: 0;
}

#excelFileDelete, #excelFile, #excelFileSendEmail, #excelFileUpdate{
  display: none;
}

.ant-tabs.ant-tabs-top.ant-tabs-line {
  width: 100%;
  height: auto;
}
.button_upload {
  width: calc(100% - 20px);
}

.button_upload span{
  width: 100%;
  text-align: center;
}

.linkExcel{
  color: blue;
  text-Decoration: underline !important;
}

.ant-spin-spinning {
  margin: auto;
}