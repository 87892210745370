.popup_Bg_Landing.rbt-active {
  opacity: 1;
  visibility: inherit;
}
.popup_Bg_Landing {
  opacity: 0;
  display: flex;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 9999;
  visibility: hidden;
  background: linear-gradient(135deg, #14141454 0%, #7f7f7fad 35%, #50505075 55%, #3c3c3c6e 69%, #b4b4b4 100%);
}
.card_Landing {
  width: 800px;
  height: 540px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  grid-gap: 13px;
  gap: 13px;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
  margin: auto;
  background-color: #2A5783;
  padding: 30px;
}

.card_Landing .br_popup_header {
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  color: #F9FCEB;
  font-weight: 400;
  font-size: 31px;
  text-transform: capitalize;
  text-align: center;
  padding: 0 30px;
}
.card_Landing p{
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  color: #F9FCEB;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  padding: 0 30px;
  margin-bottom: 0px;
}
.card_Landing .btn, .card_Landing .btn-2 {
  --color: #fff;
  --color2: rgb(0, 0, 0);
  padding: 0.8em 1.75em;
  background-color: transparent;
  border-radius: 6px;
  border: .3px solid var(--color);
  transition: .5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: 17px;
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  text-transform: uppercase;
  color: var(--color);
  margin-right: 10px;
}

.card_Landing .btn::after, .card_Landing .btn::before, .card_Landing .btn-2::before, .card_Landing .btn-2::after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: .5s ease-out;
  background-color: var(--color);
}

.card_Landing .btn::before, .card_Landing .btn-2::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.card_Landing .btn:hover::before, .card_Landing .btn-2:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.card_Landing .btn:hover::after, .card_Landing .btn-2:hover::after  {
  transform: skew(45deg) translate(-50%, -50%);
}

.card_Landing .btn:hover, .card_Landing .btn-2:hover {
  color: var(--color2);
}

.card_Landing .btn:active, .card_Landing .btn-2:active {
  filter: brightness(.7);
  transform: scale(.98);
}

.btns-landing{
  display: inline-block;
  text-align: right;
  margin-top: 30px;
}

.background_Landing {
  background-image: url("../../../assets/Banner for Hub Home page.webp");
  background-repeat: no-repeat;
  background-size: contain;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 300px;
  width: 100%;
  background-position-x: center;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .card_Landing .br_popup_header {
    font-size: 21px;
    line-height: 1;
  }
  .card_Landing {
    gap: 8px;
    padding: 20px 0;
  }
  .card_Landing p {
    font-size: 14px;
    padding: 0 10px;
  }
}