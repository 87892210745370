@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

button {
  font-family:"Nunito Sans";
}
.notfound {
  background: white;
  height: calc(100vh);
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 85%;
  svg {
    width: 100%;
  }
  h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight:bold;
  }
  h2 {
    font-weight:bold;
  }
}

.notfound .btn {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
  &.blue {
    border: 3px solid #222850;
    color: #0e0620;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background: #222850;
      z-index: -1;
      transition: 0.2s ease;
    }
    &:hover {
      color: #fff;
      background: #222850;
      transition: 0.2s ease;
      &:before {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width:768px) {
  .notfound .container {
    margin-top:70px;
    margin-bottom:70px;
    .align-self-center {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1436px) {
  .notfound {
    width: 100%;
  }
}