// Paragraphes ans others -------------------
@font-face {
    font-family: 'GothamBold';
    src: url('../assets/Fonts/GothamBold.ttf');
    
}
@font-face {
    font-family: 'GothamMedium';
    src: url('../assets/Fonts/GothamMedium.ttf');
}
@font-face {
    font-family: 'GothamLight';
    src: url('../assets/Fonts/GothamLight.ttf');
}

@font-face {
    font-family: 'Arial';
    src: url('../assets/Fonts/ARIAL.TTF');
}
@font-face {
    font-family: 'ArialBD';
    src: url('../assets/Fonts/ARIALBD.TTF');
}

// -----------------------------------------

