// bootstrap breakpoints

$xxs: '(max-width: 399px)';
$xs: '(max-width: 599.98px)';
$xs-landscape: '(max-height: 399px)';
$sm: '(max-width: 959.98px)';
$sm-landscape: '(min-width: 600px) and (max-width: 959.98px) and (max-height: 600px)';
$md: '(max-width: 1199.98px)';
$lg: '(min-width: 1199.98px)';
$xl: '(min-width: 1919px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), 
					(-o-min-device-pixel-ratio: 3/2), 
					(-webkit-min-device-pixel-ratio: 1.5), 
					(min-device-pixel-ratio: 1.5), 
					(min-resolution: 144dpi), 
					(min-resolution: 1.5dppx)'
					;
$primary-color : #222850;
$hover-color : #2228509f;
$secondary-color : #0089bc;
@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
};
