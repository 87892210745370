@import 'style/_variables.scss';

@media only screen and (max-width: 935px){
    .FiltersContainer
    {
        display : flex !important ;
        flex-direction : column !important;
    }
}


.FiltersContainer
    {
        display : flex ;
        justify-content: space-between;
        margin-top: 20px;
    }
    .TableContainer
    {
        margin-top: 40px;
    }

.root{
    display: flex;
    flex-direction: column;
    
}
.IconsContainer
{
    span
    {
        margin-right: 10px;
        font-size: 18px;
        color : $primary-color;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    span:hover
    {
        color : $hover-color;
    }
}
.body{
    background: #f2f2f2;
    margin:0px 20px 0px;
    margin-top:20px;
    border-radius: 20px;
    border:none;
}

.avatar{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: top;
    
}

.avatarUser{
    height: 80px !important;
    width: 80px !important;
    background-color: white !important;
}
.avatarUserDetails{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    text-align: center;
    .downloadIcon{
        opacity: 0;
    }
}

.avatarBig{
    height: 150px;
    width: 150px;
}


.avatar{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
}

.avatarSmall{
    height: 50px;
    width: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.App {
    font-family: sans-serif;
    text-align: center;
}
.contact{
    margin: 10px;
    box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.74);
    border-radius: 6px;
    padding:10px;
}
.iconList{
    min-width: 30px !important;
}

.inputSelectLong{
    padding: 6px 0 30px 0 !important;
}
.downloadIcon{
    width: 50px;
    height: 30px;

}
.downloadIcon:hover{
    opacity:1 !important;
}

.userDownloadImage:hover{
    opacity:0.6;
    background-color: white;
    cursor: pointer;
}

.UsersTable
{
    .MuiToolbar-root {
        display: flex;
        position: relative;
        align-items: center;
        height: 30px !important;
        min-height: 30px !important;
    }
}
.MuiTablePagination-root {
    overflow: hidden !important;
}

.btn_user_pass, .input_user_email {
    padding: 9px;
    margin-top: 15px;
    margin-right: 10px;
    border-radius: 7px;
    border: 1px solid #bfbfbf;
}
.btn_update_pass, .btn_update_email {
    border-radius: 7px;
    border: 1px solid #222850;
    background: #222850;
    color: white;
    padding: 10px;
}

