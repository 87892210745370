.document-item-card.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.document-item-card.disabled .document-item-header-container {
    pointer-events: none;
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 750px) {
    .document-item-container {
        width: 100% !important;
        padding: 20px 30px !important;
    }
    .document-item-container .document-item-header-container .document-item-header .document-item-header-title .title {
        font-size: 16px !important;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .document-item-container {
        width: calc(40% - 6px) !important;
        padding: 20px !important;
    }
}

/* laptop (portrait and landscape) ----------- */
@media only screen and (min-device-width : 1000px) and (max-device-width : 1500px) {
    .document-item-container {
        width: calc(43% - 26px) !important;
        padding: 20px 30px !important;
    }
}

.document-item-container {
    padding: 16px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0px 0px  rgba(122, 122, 122, 0.334);
    border: 1px solid #c6c5c5f1;
    transition: all 0.3s ease-in-out;
    width: calc((100% / 3) - 30px);
    &:hover
    {
        box-shadow: 0 0px 10px rgba(137, 137, 137, 0.25);
        transform: translate(0, -3px);
    }
    .document-item-header-container
    {
        .document-item-header
        {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .document-item-header-title
            {
                display: flex;
                align-items: center;
                svg
                {
                    margin-right: 10px;
                    width: 35px;
                    height: 35px;
                    fill: #3033A6;
                }
                .title
                {
                    font-size: 20px;
                    font-weight: 700;
                    color: #3033A6;
                }
            }
            .document-item-actions
            {
                display: flex;
                align-items: center;
                svg
                {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    fill: #3033A6;
                    margin-left: 9px;
                    transition: all 0.3s ease-in-out;
                    background-color: transparent;
                    border-radius: 50%;
                    padding: 1px;
                }
                svg:hover
                {
                    background-color: #ededed;
                    border-radius: 50%;
                    padding: 5px;
                }
            }
        }
        .document-item-header-date
        {
            font-size: 15px;
            font-weight:500;
            color: #A6A6A6;
            font-style: italic;
        }
    }
    .document-list-item
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .item-key
        {
            font-size: 15px;
            font-weight: 700;
            color: #3C3F87;
        }
        .item-value
        {
            font-size: 15px;
            font-weight:500;
            color: #A6A6A6;
        }
        
    }
    .document-item-body-container
    {
        .description{
            padding: 16px;
            background-color: #f6f6f6;
            border-radius: 10px;
            -webkit-user-select: text;
            .titleDescription{
                font-weight: bold;
            }
            .showText{
                color: #3033A6;
                font-size: smaller;
                cursor: pointer;
            }
        }
        .document-item-body-permissions
        {
            padding: 20px;
            border-radius: 10px;
            background-color: #f5f5f5;
            margin-top: 20px;
            .document-item-body-permissions-title
            {
                justify-content: space-between;
                display: flex;
                align-items: center;
                font-weight: 500;
                svg
                {
                    width: 20px;
                    height: 20px;
                    fill: #2A2A2B;
                    margin-right: 15px;
                }
                .document-item-body-permissions-title-access{
                    font-size: 12px;
                    svg{
                        width: 70%;
                        margin: 0;
                    }
                }
            }
            .list-item
            {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #21234f;
                margin-left: 10px;
                margin-top: 5px;
                svg 
                {
                    width: 20px;
                    height: 20px;
                    fill: #21234f;
                    margin-right: 10px;
                }
            }
        }
    }
}
.ant-progress-inner {
    height: 8px;
}

.document-item-body-container {
    margin-top: 16px;
}