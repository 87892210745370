.fileVersion
{
  .fileVersion-container
  {
    display: block;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    .item-file
    {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90%;
      margin-bottom: 20px;
      padding: 20px;
      border: 1px solid #ebebeb;
      border-radius: 10px;
      transition: all 0.3s ease;
      .itemFile-title
      {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #373737;
      }

      svg
      {
        width: 30px;
        height: 25px;
        fill: #2a2a2a;
      }

    }
    .file:hover
    {
      background-color: #f5f5f5;
      cursor: pointer;
      transform: translate(0, -3px);
    }
    .header-containerFileVersion
    {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .menu-icon
      {
        width: 20px;
        height: 20px;
        fill: #2a2a2a;
        padding: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        background-color: transparent;
        border-radius: 50px;
      }
      .menu-icon:hover
      {
        background-color: #ebebeb;

      }


    }
  }

}


