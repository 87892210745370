/* new code */
.unsub-main {
  width: 100vw;
  height: 100vh;
  background: #fefefe;
  color: #373737;
  margin: 0;
  padding: 0;
  background: #00154c;
  background: linear-gradient(0deg,#00154c,#12376e,#23487f87);
  filter: progid:DXImageTransform.Microsoft.gradient( 			startColorstr="#543093", 			endColorstr="#d960ae", 			GradientType=1 		);
}


.unsub-container {
  width: 600px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin: 0;
  position: absolute;
  background: #fefefe;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 30px;
  box-shadow: 5px 10px 40px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.unsub-inner-container {
  width: 100%;
}

#unsub-svg {
  max-width: 70%;
  position: relative;
  left: 15%;
  margin: 0 auto;
}

.unsub-bottom {
  text-align: center;
  max-width: 70%;
  position: relative;
  margin: 0 auto;
}
.unsub-bottom h2 {
  font-family: "Rokkitt", sans-serif;
  letter-spacing: 0.05em;
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  margin: 0 auto 0.25em;
}
.unsub-bottom p {
  color: #777;
  letter-spacing: 0.1em;
  font-size: 16px;
  line-height: 1.4;
  margin: 0 auto 2em;
}

.unsub-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.unsub-buttons button {
  padding: 10px 30px;
  font-size: 20px;
  background-color: #12376e;
  border: 0;
  cursor: pointer;
  border-radius: 4px;
  letter-spacing: 0.1em;
  color: #ffffff;
  margin-bottom: 15px;
  transition: all 0.25s ease-in-out;
}
.unsub-buttons button:hover {
  background-color: #00154c;
}
.unsub-buttons button:focus {
  border: none;
  outline: 0;
}

#blob-3,
#blob-2,
#mouth-happy,
#mouth-sad,
#eyebrow-happy-left,
#eyebrow-happy-right,
#eyes-laughing,
#open-mouth,
#tongue,
#mouth-scared {
  display: none;
}

@media (max-width: 699px) {
  .unsub-container {
    width: 90%;
  }

  .unsub-bottom {
    margin-top: 1em;
    max-width: 90%;
  }
}
@media (max-width: 399px) {
  .unsub-container {
    padding: 20px;
  }

  .unsub-bottom h2 {
    font-size: 24px;
  }

  .unsub-buttons {
    flex-direction: column;
  }
  .unsub-buttons button {
    margin-right: 0;
  }

  #unsub-svg {
    padding-top: 0;
  }
}
/* end new code */
