
$c-blue: #2B93D9;
$icon-eye : 'data:image/svg+xml;utf8,<svg preserveAspectRatio="xMidYMid" xmlns="http://www.w3.org/2000/svg"  xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" viewBox="0 0 24 24" height="24" width="24"><g transform="scale(0.015625 0.015625)"><path d="M512 192c-223.318 0-416.882 130.042-512 320 95.118 189.958 288.682 320 512 320 223.312 0 416.876-130.042 512-320-95.116-189.958-288.688-320-512-320zM764.45 361.704c60.162 38.374 111.142 89.774 149.434 150.296-38.292 60.522-89.274 111.922-149.436 150.296-75.594 48.218-162.89 73.704-252.448 73.704-89.56 0-176.858-25.486-252.452-73.704-60.158-38.372-111.138-89.772-149.432-150.296 38.292-60.524 89.274-111.924 149.434-150.296 3.918-2.5 7.876-4.922 11.86-7.3-9.96 27.328-15.41 56.822-15.41 87.596 0 141.382 114.616 256 256 256 141.382 0 256-114.618 256-256 0-30.774-5.452-60.268-15.408-87.598 3.978 2.378 7.938 4.802 11.858 7.302v0zM512 416c0 53.020-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.982 96 96z"/></g></svg>';



.messageContainer {
  position: relative;
}

#message {
  position: absolute;
  background: white;
  padding: 13px 19px;
  border-radius: 12px;
  bottom: 54px;
  z-index: 99;
  box-shadow: 0px 1px 28px 13px rgba(207, 207, 207, 0.24)
}
#message::before{
  content: "";
  border-width: 11px 9px 0 9px;
  border-style: solid;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
  bottom: -10px;
  margin-left: 0px;
  position: absolute;
  left: 41%;
  transition: all ease 0.3s;
}

/////////////////////
// rules list
.field__rules {
  $color: #111;

  column-count: 1;
  padding: 0 5px;
  font-size: .8em;
  list-style: none;
  margin-bottom: 0rem;

  > li {
    display: flex;
    align-items: center;
    padding: 0;
    color: rgba($color, .6);
    transition: .2s;

    &::before {
      content: '✔';
      display: inline-block;
      color: #DDD;
      font-size: 1em;
      line-height: 0;
      margin: 0 6px 0 0;
      transition: .2s;
      font: 16px Arial;
    }

    &.valid {
      color: $color;

      &::before {
        color: $c-blue;
        text-shadow: 0 0 8px currentColor;
      }
    }
  }
}


