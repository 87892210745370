
.LoadingPage
{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .Spin
    {
      animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    // animation-timing-function: linear;
    }
    svg
    {
      fill: $primary-color;
      width: 150px;
      height: 150px;
    }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}