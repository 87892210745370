.InputLabel
{
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.createFolderContainer{
  display: flex;
  flex-direction: column;
  .createFolderInput{
    width: 100%;
  }
  .InputContent
  {
    margin-top: 10px; 
  }
  .ErrorLabel
  {
    font-size: 13px;
    margin-left: 12px;
    color: #FA5252;
  }
}
