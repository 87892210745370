.upload-file-container
{
    .upload-file
    {
        display: flex;
        flex-direction: column;
        padding: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        margin-bottom: 10px;
        .header-container
        {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .icon-container
            {
                svg 
                {
                    width: 40px;
                    height: 40px;
                }
            }

        }
        .item-title
        {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
            color: #373737;
            margin-left: 10px;
        }
    }
}