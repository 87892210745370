@import '../document-item/documentItem.module.scss';
@import '../DocumentDetails/document-details.scss';

.list-documents-container
{
    margin-top: 40px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}
.create-folder-modal-header
{
    display: flex;
    align-items: center;
    svg
    {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        fill: #000;
    }
}
.documents{
    margin-top: 20px;
}

.send_request {
    position: absolute;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    align-content: center;
    background: #ffffffbf;
    z-index: 9;
    visibility: hidden;
    transition: none;

    .send_request_btn {
        padding: 15px;
        font-size: 18px;
        border-radius: 10px;
        margin: auto;
        border: none;
        color: currentColor;
        text-transform: capitalize;
        font-weight: 600;
        box-shadow: none;
        transition: none;
    }

    .send_request_btn:hover {
        background-color: #d1d1d167;
    }
}

.document-item-container:hover .send_request {
    visibility: visible;
}
