.search-input {
  width: 580px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.mantine-Input-input.mantine-TextInput-input.mantine-e81llv:focus {
  border-color: #222850;
}

.news-section {
  width: 100%;
  margin-top: 20px;
}

.news-card {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  width: calc(100% / 3);
  height: 370px;
  padding: 0 15px;
  position: relative;
  float: left;
  margin-bottom: 20px;
}

.news-section a:not(:nth-child(3n)) .news-card::before {
  content: "";
  position: absolute;
  right: 0px;
  top:0;
  width: 1px;
  height: 100%;
  background: #c8c8c8;
}

.news-img {
  overflow: hidden;
  position: relative;
}

.news-card img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  transition: transform 1s ease, -webkit-transform 1s ease;
  display: block;
}

.news-card:hover img {
  transform: scale(1.1);
}

.news-content {
  padding: 15px;
}

.news-content h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 1.3;
  color: #183354;
  transition: background-size 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: inline;
  background-image: linear-gradient(to bottom, currentColor 0%, currentColor 98%);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
  font-family: sans-serif;
}

.news-content:hover h3 {
  background-size: 100% 1px;
}

.news-content time {
  display: flex;
  align-items: center;
  color: #6D757F;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  font-family: sans-serif;
  margin-top: 6px;
  .calendar-icon {
    height: 0.67em !important;
    padding: 2px !important;
    width: 0.67em !important;
  }
}

.news-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.75;
  color: #545E69;
  text-transform: capitalize;
  margin: 5px 0;
  font-family: sans-serif;
}
.news-pagination {
  display: block;
  width: 100%;
  float: inline-end;
  margin: 10px 0 60px 0;

  .btn {
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, #00154c, #12376e, #23487f);
    color: #fff;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  .btn span {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: top 0.5s;
  }

  .btn-text-one {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #fff;
  }

  .btn-text-two {
    position: absolute;
    width: 100%;
    top: 150%;
    left: 0;
    transform: translateY(-50%);
    color: #fff;
  }

  .btn:hover .btn-text-one {
    top: -100%;
  }

  .btn:hover .btn-text-two {
    top: 50%;
  }
}

/* Add responsive design as needed */
@media (max-width: 768px) {
  .news-card {
    width: 100%;
    padding: 0px;
    margin-bottom: 20px;
    border: 1px solid #c8c8c8;
  }
  .news-card::before {
    width: 0px;
  }
  .news-section a:not(:nth-child(3n)) .news-card::before {
    width: 0px;
  }
}