.outter{
    display: flex;
    position: fixed;
    left: 50%;
    right: auto;
    bottom: 24px;
    transform: translateX(-50%);
    flex-direction: column;
    grid-gap: 20px;
    gap: 12px;
    z-index: 999;
}

.root{
    position: relative !important;
    margin-top: 5px;

}


.error{
    padding: 8px 16px;
    border-radius: 10px;
    color: white;
    background-color: #D03D49 !important;
}

.success{
    padding: 8px 16px;
    border-radius: 10px;
    color: white;
    background-color: #104420 !important;
}

.info{
    padding: 8px 16px;
    border-radius: 10px;
    color: white;
    background-color: #681D49 !important;
}