.ant-select-selection-item, .ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.rbc-day-bg.rbc-today, .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
  background-color: white;
}

/*.rbc-date-cell.rbc-now.rbc-current {
  .rbc-button-link {
    background-color: #222850;
    color: white;
    text-align: center;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    margin-bottom: 1px;
  }
}*/
.rbc-date-cell.rbc-now .rbc-button-link{
  background-color: #222850;
  color: white;
  text-align: center;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  margin-bottom: 1px;
}

.rbc-header.rbc-today {
  background-color: white;
  .rbc-button-link {
    color: #222850;
    font-weight: bold;
  }
}

.rbc-current-time-indicator {
  background-color: #222850;
}

.rbc-event, .rbc-event.rbc-selected {
  background-color: #424766;
}

.rbc-row-segment {
  margin-bottom: 0px;
  padding: 0 5px 1px 5px !important;
}

.event-date {
  font-size: 8px;
  font-weight: 400;
  letter-spacing: .06em;
  margin: 0;
}

.rbc-show-more {
  background-color: transparent !important;
  margin-top: 0px;
  color: #0089bc !important;
}

.event-category {
  font-size: 10px;
  letter-spacing: .03em;
  margin: 0;
}

.event-title {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .04em;
  text-transform: capitalize;
  margin: 0;
  line-height: 12px;
}

.asterisk-span  {
  color: #fa5252;
}
.rbc-month-view {
  display: grid !important;
}

.rbc-month-view, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper,.rbc-toolbar button, .ant-picker {
  border-radius: 7px !important;
  box-shadow: 0px 15px 40px 0px rgba(150, 150, 150, 0.13);
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0px !important;
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-divider, .ant-divider-vertical {
  visibility: hidden;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active{
  box-shadow: none !important;
}

.rbc-overlay{
  padding: 10px 15px !important;
  border-radius: 7px;
  box-shadow: 0px 15px 98px 0px rgba(176, 176, 176, 0.43) !important;
}

.rbc-overlay div{
  border-radius: 5px;
  margin-bottom: 5px;
}

.ant-select-selector, .ant-input-affix-wrapper, .ant-picker {
  border: 1px solid #222850 !important;
}

.ant-picker-separator, .ant-picker-suffix, .ant-select-arrow {
  color: #222850 !important;
}

.filters-bar {
  margin-bottom: 6px;
  margin-top: 18px;
  display: inline-flex;
  gap: 5px;
}

.ant-input-search, .ant-picker {
  color: rgba(0, 0, 0, 0.65);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #d4d4d4;
}

.ant-picker-input input::placeholder, .ant-input-wrapper.ant-input-group input::placeholder {
  color: rgba(0, 0, 0, 0.65);
}

.rbc-btn-group {
  margin-bottom: 10px;
}

/* start Button View in Calendar */

/* end Button View in Calendar */

/* start button toggle & button refresh */
.checkbox_event {
  position: relative;
  width: 200px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #424766;
  color: white;
  margin-right: 10px;
  border: none;
  border-radius: 10px;
}

.checkbox_event, .button__icon, .button__text {
  transition: all 0.3s;
}

.checkbox_event .button__text {
  transform: translateX(40px);
  color: #fff;
  font-weight: 600;
  margin-top: -1px;
}

.checkbox_event .button__icon {
  position: absolute;
  transform: translateX(153px);
  height: 100%;
  width: 50px;
  background-color: #424766;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox_event .svg {
  width: 20px;
  fill: #fff;
}

.checkbox_event:hover {
  background: #424766;
}

.checkbox_event:hover .button__text {
  color: transparent;
}

.checkbox_event:hover .button__icon {
  width: 148px;
  transform: translateX(20px);
}

.checkbox_event:active .button__icon {
  background-color: #424766;
}

.checkbox_event:active {
  border: 1px solid #424766;
}
.button__text_event {
  color: #fff;
  font-weight: 600;
  margin-top: -1px;
  text-align: center;
  width: 100%;
}
/* end button toggle & button refresh */

.si-glyph.si-glyph-forward-page {
  cursor: pointer;
}



