.bg-event {
  background: linear-gradient(rgba(0, 60, 125, 0.48), rgba(1, 59, 121, 0.53)), url('../../../assets/banner_image_popup.webp') center / cover;
  border-radius: 0px 0 8px 8px;
  max-height: 369px;
}

.card.shadow.mail_log {
  height: calc(100% - 1.5rem);
  max-height: 345px;
}
.card.shadow.mail_log .card-body{
  overflow-y: auto;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 4px !important;
}

.card-header .text-blue {
  color: #1f2553;
}

.card-body {
  margin: auto;
  align-items: center;
  width: 100%;

  .swiper-slide {
    display: block;
  }
  .ant-btn.ant-btn-circle.ant-btn-icon-only
  {
    margin-left: 10px;
  }

  time{
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    svg {
      margin-right: 5px;
      width: 0.8em;
      height: 0.8em;
    }
  }

  article {
    --img-scale: 1.001;
    --title-color: black;
    --link-icon-translate: -20px;
    --link-icon-opacity: 0;
    position: relative;
    border-radius: 10px;
    box-shadow: none;
    background: #fff;
    transform-origin: center;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    margin: 10px;
  }

  article a::after {
    position: absolute;
    inset-block: 0;
    inset-inline: 0;
    cursor: pointer;
    content: "";
  }

  /* basic article elements styling */
  article h2 {
    margin: 0 0 10px 0;
    font-size: 1rem;
    letter-spacing: 0.06em;
    color: var(--title-color);
    transition: color 0.3s ease-out;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  figure {
    margin: 0;
    padding: 0;
    aspect-ratio: 16 / 6;
    overflow: hidden;
  }

  article img {
    max-width: 100%;
    transform-origin: center;
    transform: scale(var(--img-scale));
    transition: transform 0.4s ease-in-out;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .article-body {
    padding: 15px;
  }

  article a {
    display: block;
    align-items: center;
    text-decoration: none;
    color: #222850;
    background-color: transparent;
    border: none;
  }

  article a:focus {
    outline: 1px dotted #222850;
  }

  article a .icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    transform: translateX(var(--link-icon-translate));
    opacity: var(--link-icon-opacity);
    transition: all 0.3s;
  }

  /* using the has() relational pseudo selector to update our custom properties */
  article:has(:hover, :focus) {
    --img-scale: 1.1;
    --title-color: #222850;
    --link-icon-translate: 0;
    --link-icon-opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 26px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }


  /************************
  Generic layout (demo looks)
  **************************/

  @media screen and (max-width: 960px) {
    article {
      container: card/inline-size;
    }
    .article-body p {
      display: none;
    }
  }

  @container card (min-width: 380px) {
    .article-wrapper {
      display: grid;
      grid-template-columns: 100px 1fr;
      gap: 16px;
    }
    .article-body {
      padding-left: 0;
    }
    figure {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    figure img {
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  .sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

.bg-white.sticky-footer {
  margin-top: 50px;
}

.title_files {
  background: white;
  padding: 1rem 1rem 1rem 4rem;
  box-shadow: 0px 0px 10px 4px rgba(150, 150, 150, 0.24);
  position: relative;
  margin: 15px 0px;
  border-radius: 10px;
}
.title_files::before {
  content: "";
  color: white;
  border-radius: 10px 0px 0px 10px;
  background: url("../../../assets/documents/svg/folder.svg") #1f2553 no-repeat center;
  display: inline;
  width: 45px;
  height: 100%;
  background-size: 25px;
  margin-right: 1rem;
  margin-left: -4rem;
  position: absolute;
  bottom: 0;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none !important;
}

/* loader */
.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 89vh;
  width: 100%;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #222850;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #222850;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #19288a;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}
/* end loader */

#range-filter .google-visualization-controls-rangefilter{
  display: flex;
  margin-left: 30px;
}
.google-visualization-controls-stringfilter .google-visualization-controls-label {
  padding-top: 0em !important;
}
.google-visualization-controls-stringfilter input {
  border-radius: 6px;
}


/* Media query for small screens */
@media (max-width: 768px) {
  .swiper-container {
    slidesPerView: 2;
    spaceBetween: 20;
  }
}

.ant-btn.ant-btn-circle.ant-btn-icon-only {
  margin-left: 10px;
}

.recharts-responsive-container {
  z-index: 9;
  position: relative;
}