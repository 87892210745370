.dialogOutter{
  padding: 32px;
  .buttonGroup{
    padding: 16px 0;
    display: flex;
    gap: 32px;
    justify-content: center;
    width: 100%;
  }
}
