.document-item-body-title
{
   padding: 20px 30px;
   background-color: #f5f5f5;
   margin: 10px 0px;
   border-radius: 10px;
  .permision-title
  {
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: #228BE6;
  }
  .permissions-content
  {
    margin-top: 10px;
  }
}
.input-container
{
  margin-top: 20px;
}
