


.cardCategoryWhite{
  color: rgba(255,255,255,.62);
  margin: 0;
  fontSize: 14px;
  marginTop: 0;
  marginBottom: 0;
}



.cardTitleWhite{
  color: white;
  margin-top: 0px;
  min-height: auto;
  font-weight: 300;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin-bottom: 3px;
  text-decoration: none;
}
h4{
  font-size: 1.3em;
  font-weight: 300;
}
.title{
  padding-bottom: 50px;
}
.header{
  padding: 15px;
  margin-top: -20px;
  border-radius: 3px;
  box-shadow: 0 4px 20px 0 rgb(0, 0, 0 / 14%), 0 7px 10px -5px rgb(156, 39, 176 / 40%);
  background-image:  linear-gradient(60deg, #222850, #222850)
}
.body{
  box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.54);
  margin-top: 30px;
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  width: 90%;
  border: 0;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  word-wrap: break-word;
}
.gridField{
  padding: 10px;
  margin: 27px 0 0 0;
  width: 100%;
}
.field:after{
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-delay: 0ms;
  border-bottom: 2px solid #3f51b5;
  pointer-events: none;
}
.gridProfile{
  padding: 50px 10px 0 15px !important;
}
.gridPassword{
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.54);
  border-radius: 6px;
  flex-direction: column;
  text-align: center;
  padding: 50px 15px 20px 15px !important;
  margin: 15px !important;
}
.cardProfile{
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.54);
  border-radius: 6px;
  margin-bottom: 30px;
  flex-direction: column;
  margin-top: 30px;
  text-align: center;
}
.assistantInfo{
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.54);
  border-radius: 6px;
  padding:10px;
}

.cardHeaderProfile{
margin: -50px auto 0;
padding: 0;
overflow: hidden;
max-width: 130px;
box-shadow: 0 16px 1px -12px rgb(0, 0, 0 / 56%), 0 4px 15px 0px rgb(0, 0, 0 / 12%), 0 3px 8px -5px rgb(0, 0, 0 / 20%);
max-height: 130px;
border-radius: 50%;
display: flex;
justify-content: center;

}
.cardFullName{
  font-weight: 400;
}
.avatar{
height: 130px !important;
width: 130px !important;
margin-top: -12px;
  }
.buttonProfile{
color: white !important;
background-image: linear-gradient(60deg, #222850, #222850);
width: 150px;
height: 40px;
}
.gridButtonProfile{
text-align: center;
padding: 40px;

}
.contact{
  margin: 10px;
  box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.74);
  border-radius: 6px;
  padding:10px;
}
.peopleInformation{
  margin: 10px;
  box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.74);
  border-radius: 6px;
  padding:10px;
}
.administration{
  margin: 10px;
  box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.74);
  border-radius: 6px;
  padding:10px;
}
.modalStyle {
  position: absolute;
  width: 40%;
  overflowY: auto;
  overflow: hidden;
  display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.paper {
  padding: 20px;
}
.modalStyleArea {
  position: absolute;
  width: 40%;
  overflowY: auto;
  overflow: hidden;
  display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.paperArea {
  padding: 20px;
}
.gridAddNew{
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  word-wrap: break-word;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0 ,0 ,0,0.54);
  border-radius: 6px;
  flex-direction: column;
  text-align: center;
  margin: 3px !important;
}
.ant-select-clear {
  display: none !important;
}