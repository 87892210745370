.documents
{
    .dropzone{
        position: absolute;
        height: 100%;
        width: 75%;
        z-index: -1;
        border: none;
    }
    .dropzone:hover{
     background-color: transparent !important;
     color: transparent !important;
     cursor: auto !important;
    }
    .actions-header-container
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .actions-header
        {
            display: flex;
            align-items: center;
            .back-arrow
            {
                margin-right: 10px;
                padding: 5px;
                border-radius:50%;
                width: 30px;
                height: 30px;
                transition: all 0.3s ease-in-out;
                box-sizing: border-box;
            }
            .back-arrow:hover 
            {
                cursor: pointer;
                background-color: #2a2a2a22;
            }
        }
        
        .documents-actions
        {
            display: flex;
            align-items: center;

            .action-button
            {
                display: flex;
                align-items: center;
                margin-left: 10px;
                svg 
                {
                    margin-right: 5px;
                }
            }
        }
        
    }
    .documents-container
    {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        flex-wrap: wrap;
        .input-action-container
        {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .item-link {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(40% - 20px);
            padding: 10px;
            border: 1px solid #ebebeb;
            border-radius: 10px;
            transition: all 0.3s ease;
        }

        .item-folder {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(25% - 20px);
            //margin-bottom: 15px;
            padding: 10px;
            border: 1px solid #ebebeb;
            border-radius: 10px;
            transition: all 0.3s ease;
            .item-title
            {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
                color: #373737;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            svg 
            {
                width: 30px;
                height: 30px;
            }

            .folder-icon
            {
                width: 30px;
                height: 30px;
                fill: navajowhite;
            }
            
        }
        .folder:hover
        {
            background-color: #f5f5f5;
            cursor: pointer;
            transform: translate(0, -3px);
        }
        .header-container
        {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .menu-icon
            {
                width: 20px;
                height: 20px;
                fill: #2a2a2a;
                padding: 5px;
                cursor: pointer;
                transition: all 0.3s ease;
                background-color: transparent;
                border-radius: 50px;
            }
            .menu-icon:hover
            {
                background-color: #ebebeb;

            }


        }
    }

}

.menu-container
{
    .menu-item
    {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
    }
    .delete
    {
        color: #FA5252 !important;
    }
}

.create-active
{
    border: 1px solid #8785ff !important;
    box-shadow: 0 0 0 3px #8785ff30;
    .header-container
    {
        svg {
            fill: #2a2a2a;
        }
        .link-icon {
            fill: none !important;
            width: 30px;
            height: 30px;
        }
    }
    .group-action-container
    {
        width: 30%;
        svg 
        {
            width: 16px !important;
            height: 16px !important;
        }
    }
}
.create-active:hover 
{
    background-color: transparent !important;
    cursor: default;
    transform: translate(0, 0) !important;
}
.ErrorLabel
{
  font-size: 13px;
  margin-left: 12px;
  color: #FA5252;
}
.dropzone
{
    background-color: transparent;
}
.dropzone:hover
{
    background-color: transparent !important;
}
