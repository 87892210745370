/* Override the visibility of the divider */
.ant-divider, .ant-divider-vertical {
  visibility: visible !important;
  height: 100%;
  margin: auto;
  display: inherit;
}
.ant-select-selection-item {
  width: 100%;
}


/* AddCountry.css */
.toggle-input {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.toggle-input.show {
  max-height: 100px; /* Adjust based on your content's height */
}

.toggle-input.hide {
  max-height: 0;
}

/* Model */
.ant-modal-footer div {
  display: flex;
}