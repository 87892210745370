.LoginContainer
{
  .logo{
    width: 30%;
  }
  .LoginLeftSide
  {
    height: 100%;
    width: 40%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding: 50px;
    left: 0;
    .LoginHeader
    {
      margin-top: 20%;
      .LoginTitle
      {
        font-family: 'ArialBD';
        font-size: 30px;
        color: #1f2553;
        margin-bottom: 20px;
      }
      .LoginParagraph
      {
        font-family: 'GothamLight';
        font-size: 14px;
        color: #BAC5CB;
        margin-bottom: 20px;
      }
    }
    .FormContainer
    {
      margin-top: 20px;
      width: 90%;
      .forgotPassword
      {
        font-family: 'GothamLight';
        font-size: 14px;
        color: #222850;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
      .forgotPassword:hover
      {
        text-decoration: underline;
        color: #1f2553;
      }
    }
  }
  .LoginRightSide
  {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding: 20px;
    right: 0;
    background-image: url("./../../assets/login-page.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #2A5884;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .LoginContainer .LoginLeftSide {
    order: 2;
    height: auto;
    width: calc(100% - 60px);
    position: relative;
    z-index: 0;
    padding: 30px;
    margin-top: -65px;
  }
  .LoginContainer .LoginRightSide {
    order: 1;
    height: 190px;
    width: calc(100%);
    position: relative;
    z-index: 1;
    padding: 40px 20px;
  }
  .LoginContainer {
    display: grid;
  }
}


