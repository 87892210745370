@import '../../../src/style/_variables.scss';
@import './Dropdown.scss';
@import './LoadingPage.scss';

@media only screen and (max-width: 1436px){
  .ContentContainer
  {
    width: 94% !important ;
    margin-left: 3% !important;
  }
  .SideBarContainer
  {
    width: 90% !important ;
    margin-left: 0% !important;
    margin-top: 20px !important;
    position: relative !important;
    height: auto !important;
  }
  .MenuIcon {
    display: block !important;
  }
  .WebSidebar {
    display: none !important;
  }
  .MobileSidebar {
    display: block !important;
  }
}

.ant-drawer-close {
  color : #fff !important;
}
.WebSidebar {
  display: block;
}
.MobileSidebar {
  display: none;
}
.MenuIcon {
  display: none;
}

  .SideBarContainer {
    width: 15%;
    position: fixed;
    height: 100vh;
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    .SideBarNavBar {
      display: flex;
      flex-direction: column;
      width: 100%;
      .SideBarNavBarItem:hover {
        background-color: #c4c4c41c;
        // color: white;
      }
      .SideBarNavBarItem {
        display: flex;
        font-family: 'ArialBD';
        flex-direction: row;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 10px;
        width: 90%;
        transition: all 0.3s ease-in-out;
        color: #fafafa;
        cursor: pointer;
        text-decoration: none;
        .NavbarIcon {
          margin-right: 10px;
        }
      }
      .navitemSelected {
        background-color: #c4c4c41c;
        color: #fafafa;
      }
    }
  }
.MainAppContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x : hidden ;
  .TaxandIcon {
    width: 60%;
    margin-bottom: 100px;
  }
  .ContentContainer {
    margin-left: 18%;
    margin-right: 3%;
    width: 80%;
    transition: all 0.3s ease-in-out;
    .headerIntroContainer {
      background-color: #f6f6f6;
      padding: 2%;
      animation: 3s ease-in 1s infinite reverse both running slidein;
      border-radius: 20px;
      .introMessage {
        -webkit-user-select: text;
        font-size: inherit;
        letter-spacing: 0.02em;
      }
    }
    .Header {
      padding: 5px;
      display: flex;
      font-family: 'ArialBD';
      justify-content: space-between;
      align-items: center;
      color: $primary-color;
      .UserInfo {
        font-size: 14px;
        font-family: 'ArialBD';
        border-radius: 10px;
        padding: 10px;
        background-color: transparent;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      .UserInfo:hover {
        background-color: #d1d1d167;
      }
      .Username {
        display: flex;
        align-items: center;
        font-size: 20px;

      }
      svg {
        fill : $primary-color;
        width: 35px;
        margin-right: 30px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }
      svg:hover {
        fill: $hover-color;
      }
    }
  }
  .Collapsed {
    width: 2% !important;
    .NavbarText {
      display: none !important;
    }
  }
  
}
.ContainerRout {
  padding: 20px;
}
@media screen and (max-width: 950px) {
  .ContainerRout {
    padding: 20px 0px;
  }
}

.documentsDisclaimer {

}
